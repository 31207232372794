@import '~antd/dist/antd.less';

@font-color: fade(#000, 80);
@font-weight-regular: 400;
@font-weight-bold: 600;
@font-weight-black: 900;

html,
body {
  overflow: hidden;
  scroll-behavior: smooth;
}

@media screen {
  body,
  body * {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell',
      'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    //    color: @font-color;
  }
}
@media print {
  body,
  body * {
    font-size: 11px !important;
    font-weight: 700 !important;
    // font-family: Tahoma, Arial, 'Segoe UI', Calibri, Geneva, Verdana !important;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell',
      'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  }
}

.print-mix,
.print-mix *,
.print-mix > * {
  font-size: 11px !important;
  font-weight: 900 !important;
  // font-family: Tahoma, Arial, 'Segoe UI', Calibri, Geneva, Verdana !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

// SOLVE SAFARI AUTO-ZOOM
input,
input,
select,
textarea {
  font-size: 16px;
  touch-action: none;
}
.text-primary {
  color: @primary-color !important;
}
.text-success {
  color: @success-color !important;
}
.text-info {
  color: @info-color !important;
}
.text-error {
  color: @error-color !important;
}
.bg-primary {
  background-color: @primary-color !important;
}
.shadow-down {
  box-shadow: @shadow-2;
}
.bar-bg {
  background: rgba(255, 255, 255, 0.2);
}
.clickable {
  cursor: pointer;
}
.scroll-smooth {
  scroll-behavior: smooth;
}
.header-active {
  color: @primary-color !important;
}
.section {
  padding: 1em;
}
// ._section--active {
//   // box-shadow: @shadow-2;
//   // padding: 1em;
// }
.padding-content {
  padding: 1em;
}
.pb-1 {
  padding-bottom: 1em;
}
.pt-1 {
  padding-top: 1em;
}
.pl-1 {
  padding-left: 1em;
}
.pr-1 {
  padding-right: 1em;
}

.text-bold {
  font-weight: bold;
}

.height-full {
  height: 100%;
}
.width-full {
  width: 100%;
}
.disabled {
  opacity: 0.6;
}

._section--active .ant-divider::before,
._section--active .ant-divider::after {
  border-top: 1px solid fade(@primary-color, 40) !important;
}
// .ant-divider-horizontal.ant-divider-with-text-left::before {
//     width: 1em;
// }

.selected {
  color: @primary-color;
  font-weight: 600;
  background-color: @input-bg;
}
.selected svg {
  fill: @primary-color;
}
// .shadow-down {
//   // box-shadow: @shadow-2;
// }
// .shadow-up {
//   // box-shadow: @shadow-2;
// }
// #app-header {
//   // box-shadow: @shadow-2;
// }
.ant-layout,
.ant-layout-content,
.ant-layout-sider-children,
.scrollbar-container/*,*/.ant-drawer-wrapper-body,
.ant-drawer-content,
.ant-drawer-body {
  display: flex;
  flex: 1 !important;
  max-width: 100%;
  align-items: stretch;
  justify-content: stretch;
}
.ant-drawer-wrapper-body {
  justify-content: stretch;
  align-items: stretch;
  height: auto;
}
.ant-row {
  flex-grow: 1;
  flex-shrink: 0;
}
.ant-divider.ant-divider-horizontal {
  margin: 0.5em 0;
}
.ant-divider-inner-text {
  padding-left: 0;
}
.button-menu {
  position: fixed;
  right: 1em;
  top: 20px;
  z-index: 10;
}
.ant-list-item {
  padding-bottom: 1em;
  & > :not(:first-child) {
    margin-left: 1.5em;
  }
}
.margin-left-0,
.ant-list-item-action {
  margin-left: 0;
}
.ant-menu-item,
.ant-menu-submenu {
  transition: font-weight 0.2s ease-in-out;
  font-weight: @font-weight-regular;
  color: @font-color !important;
}
.ant-menu-item-active,
.ant-menu-item-selected,
.ant-menu-submenu.ant-menu-submenu-selected {
  background-color: transparent !important;
  color: @primary-color !important;
}
.ant-menu-item-selected {
  font-weight: @font-weight-bold;
}
.ant-list-item:hover {
  color: @primary-color;
}

.order-offer-status .ant-badge-count, .custom-badge {
  background-color: transparent;
  color: @primary-color;
  font-weight: @font-weight-bold;
  font-size: 1.2em;
}
.section-header {
  font-size: 0.8em !important;
  opacity: 0.5;
}

.section-header.header-active {
  opacity: 1;
}

.loader,
.loader > .ant-spin-container {
  display: flex;
  flex: 1 1 auto;
  height: 100% !important;
  width: 100% !important;
  & > * {
    flex: 1 1 auto;
  }
}

.grow {
  flex-grow: 1;
}
.shrink {
  flex-shrink: 1;
}

.grow .ant-btn {
  width: 100%;
}

.ant-card-head {
  padding: 0 5px !important;
}

th.billTableHead {
  font-weight: bold !important;
  font-size: 16px;
  @media print {
    font-size: 12px;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    background: #ffffff !important;
  }
}

.ant-table-tbody > tr > td {
  @media print {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.ant-notification-hook-holder,
.ant-notification-notice {
  max-width: calc(100vw - 16px);
}

@media screen and (max-width: 575px) {
  .settings-switch {
    max-width: 100%;
    flex: 0 0 75%;
    .settings-switch-label {
      .ant-form-item-label {
        max-width: 70%;
      }
      .ant-form-item-control {
        max-width: 30%;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .location-name {
    //max-width: 100%;
    flex: 0 0 75%;
  }

  .location-price {
    max-width: 40%;
    flex: 0 0 75%;
  }

  .misc-item{
    max-width: 25%;
    flex: 0 0 75%;
  }
}

@media only screen and (max-width: 768px), only screen and (max-device-width: 768px) {
  .ant-picker-panel-container .ant-picker-panels {
    flex-wrap: wrap;
  }
}
@media all {
  .page-break {
    display: none;
  }
}

@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
    padding: 0.5em 1em;
  }
  .ant-card-bordered {
    border: none;
  }
  .bill-container .ant-table {
    margin: 0 -15px;
  }
  .print-text10 {
    font-size: 10px;
  }
  .print-styles {
    font-size: 11px !important;
    font-weight: 900 !important;
    // font-family: Tahoma, Arial, 'Segoe UI', Calibri, Geneva, Verdana !important;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell',
      'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  }
}

.title-print {
  @media print {
    font-size: 12px !important;
    line-height: 1.2;
  }
}

.ant-table-cell {
  @media print {
    padding: 0px 0px;

    span {
      text-transform: capitalize;
    }
  }
}

.bill-stats {
  padding: 16px;
  font-size: 14px;
  font-weight: bold;
  border-top: solid 1px #a60053;
  @media print {
    padding: 5px 0 0;
  }
}

.report-inventory {
  @media print {
    margin: 0;
    margin-top: 0;
    tr > td {
      padding: 2px;
      font-size: 10px;
    }
    & > * {
      .print-mix();
    }
  }
}

.print-only {
  display: none;
  @media print {
    display: block;
  }
}

@primary-color: #A60053;@secondary-color: #BDBDBD;@info-color: #2f54eb;@error-color: #f5222d;@layout-header-height: auto !important;@layout-header-padding: @padding-md 20px;@layout-header-background: #fff;@typography-title-margin-bottom: 0;@btn-primary-bg: @primary-color;@input-bg: rgba(220,220,220, 0.2);@input-color: @primary-color;@divider-orientation-margin: 0;@shadow-2: 0 1px 1px -1px rgba(0, 0, 0, 0.08), 3px 2px 6px 0 rgba(0, 0, 0, 0.05), 0 3px 12px -1px rgba(0, 0, 0, 0.03);